import ConfirmDeleteModal from 'components/elements/ConfirmDeleteModal'

import deckService from 'services/deck.service'
import ToastService from 'services/toast.service'

type Props = {
  deckId: string | number
  name: string
  open: boolean
  onClose: () => void
  onDeleted?: () => void
}

const QuickDeleteDeckModalConfirm = ({ deckId, name, open, onClose, onDeleted }: Props) => {
  const handleDeleteDeck = () => {
    if (!deckId) return Promise.reject()

    return deckService
      .deleteDeck(deckId, true)
      .then(() => {
        ToastService.create(`${name} deleted`, 'Deck service', 'success')

        if (onDeleted) return onDeleted()
      })
      .catch(() => ToastService.create('Unable to delete deck', 'Deck service'))
  }

  return (
    <ConfirmDeleteModal
      open={open}
      onClose={onClose}
      onDelete={handleDeleteDeck}
      header={`Delete ${name}`}
      message="Are you sure you want to delete this deck? This action is irreversible."
      buttonText="Delete deck"
      delayEnabledTime={3000}
    />
  )
}

export default QuickDeleteDeckModalConfirm
